import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './App.css';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faBlog } from '@fortawesome/free-solid-svg-icons';

function App() {
    return (
        <div className='App'>
            <div className='Text'>
                Feel free to say hi 👋 or drop me a message on any of these.
            </div>
            <div className='Icons'>
                <a href='https://t.me/deveshptl'>
                    <FontAwesomeIcon icon={faTelegram} />
                </a>
                <a href='https://instagram.com/devesh.patel.13/'>
                    <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href='mailto:mehacker369@gmail.com'>
                    <FontAwesomeIcon icon={faEnvelope} />
                </a>
                <a href='https://blog.deveshptl.com/'>
                    <FontAwesomeIcon icon={faBlog} />
                </a>
            </div>
        </div>
    );
}

export default App;
